export const Episodes = [
    {
        "id": 5548,
        "movieName": "Robocop",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 6951,
        "movieName": "Turner & Hooch",
        "movieYear": "1989",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 377,
        "movieName": "Terror på Elm Street",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 754,
        "movieName": "Face/Off",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 602,
        "movieName": "Independence day",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 5,
                "opinion": false
            }
        ]
    },
    {
        "id": 2614,
        "movieName": "24-timmarsjakten",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 9535,
        "movieName": "Analyze this",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 881,
        "movieName": "På heder och samvete",
        "movieYear": "1992",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": false
            }
        ]
    },
    {
        "id": 19995,
        "movieName": "Avatar",
        "movieYear": "2009",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 5,
                "opinion": false
            }
        ]
    },
    {
        "id": 319,
        "movieName": "True romance",
        "movieYear": "1993",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 402,
        "movieName": "Basic instinct",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 492,
        "movieName": "Being John Malkovich",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 9671,
        "movieName": "Crocodile Dundee",
        "movieYear": "1986",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 744,
        "movieName": "Top gun",
        "movieYear": "1986",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 6950,
        "movieName": "Outbreak - I farozonen",
        "movieYear": "1995",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 9326,
        "movieName": "Den vilda jakten på stenen",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 856,
        "movieName": "Who framed Roger Rabbit?",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 6,
                "opinion": true
            }
        ]
    },
    {
        "id": 1541,
        "movieName": "Thelma & Louise",
        "movieYear": "1991",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 5,
                "opinion": true
            }
        ]
    },
    {
        "id": 623,
        "movieName": "En fisk som heter Wanda",
        "movieYear": "1988",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 100,
        "movieName": "Lock, stock and two smoking barrels",
        "movieYear": "1998",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            }
        ]
    },
    {
        "id": 4982,
        "movieName": "American gangster",
        "movieYear": "2007",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 37136,
        "movieName": "Den nakna pistolen",
        "movieYear": "1988",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 550,
        "movieName": "Fight Club",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 9388,
        "movieName": "Thank you for smoking",
        "movieYear": "2005",
        "opinions": [
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 9522,
        "movieName": "Wedding Crashers",
        "movieYear": "2005",
        "opinions": [
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 4995,
        "movieName": "Boogie Nights",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 175,
        "movieName": "Det Stora Blå",
        "movieYear": "1988",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 15,
        "movieName": "Citizen Kane",
        "movieYear": "1941",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 629,
        "movieName": "The Usual Suspects",
        "movieYear": "1995",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 1089,
        "movieName": "Point Break",
        "movieYear": "1991",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 7,
                "opinion": true
            }
        ]
    },
    {
        "id": 153,
        "movieName": "Lost in Translation",
        "movieYear": "2003",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 807,
        "movieName": "Seven",
        "movieYear": "1995",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 8,
                "opinion": true
            }
        ]
    },
    {
        "id": 9475,
        "movieName": "En Kvinnas Doft",
        "movieYear": "1992",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 8,
                "opinion": false
            }
        ]
    },
    {
        "id": 9473,
        "movieName": "South Park - Bigger, longer & uncut",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 19181,
        "movieName": "Sällskapsresan 2 - Snowroller",
        "movieYear": "1985",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 9,
                "opinion": true
            }
        ]
    },
    {
        "id": 1885,
        "movieName": "Karate Kid",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 10,
                "opinion": true
            }
        ]
    },
    {
        "id": 274,
        "movieName": "När Lammen Tystnar",
        "movieYear": "1991",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 13,
        "movieName": "Forrest Gump",
        "movieYear": "1994",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 16003,
        "movieName": "Jägarna",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 11031,
        "movieName": "This is Spinal Tap",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 9,
                "opinion": true
            }
        ]
    },
    {
        "id": 88,
        "movieName": "Dirty Dancing",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 11,
                "opinion": true
            }
        ]
    },
    {
        "id": 603,
        "movieName": "The Matrix",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 1572,
        "movieName": "Die Hard - With a vengeance",
        "movieYear": "1995",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 510,
        "movieName": "Gökboet",
        "movieYear": "1975",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 10719,
        "movieName": "Elf",
        "movieYear": "2003",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 8,
                "opinion": true
            }
        ]
    },
    {
        "id": 39382,
        "movieName": "Ivanhoe",
        "movieYear": "1982",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": false
            }
        ]
    },
    {
        "id": 7446,
        "movieName": "Tropic Thunder",
        "movieYear": "2008",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 95,
        "movieName": "Armageddon",
        "movieYear": "1998",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 194,
        "movieName": "Amelie från Montmartre",
        "movieYear": "2001",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 1103,
        "movieName": "Flykten Från New York",
        "movieYear": "1981",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 712,
        "movieName": "Fyra Bröllop och en Begravning",
        "movieYear": "1994",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 1933,
        "movieName": "The Others",
        "movieYear": "2001",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 9336,
        "movieName": "Polisskolan",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 984,
        "movieName": "Dirty Harry",
        "movieYear": "1971",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": false
            }
        ]
    },
    {
        "id": 9602,
        "movieName": "En Prins i New York",
        "movieYear": "1988",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 34683,
        "movieName": "Änglagård",
        "movieYear": "1992",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 14,
        "movieName": "American Beauty",
        "movieYear": "1999",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 12,
                "opinion": true
            }
        ]
    },
    {
        "id": 279,
        "movieName": "Amadeus",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 11072,
        "movieName": "Det Våras För Sheriffen",
        "movieYear": "1974",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 2108,
        "movieName": "Breakfast Club",
        "movieYear": "1985",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 9802,
        "movieName": "The Rock",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 11634,
        "movieName": "Fucking Åmål",
        "movieYear": "1998",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 12,
                "opinion": true
            },
            {
                "participantId": 13,
                "opinion": true
            }
        ]
    },
    {
        "id": 9277,
        "movieName": "Blåsningen",
        "movieYear": "1973",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 14,
                "opinion": true
            }
        ]
    },
    {
        "id": 641,
        "movieName": "Requiem For a Dream",
        "movieYear": "2000",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 90,
        "movieName": "Snuten i Hollywood",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 597,
        "movieName": "Titanic",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 563,
        "movieName": "Starship Troopers",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 627,
        "movieName": "Trainspotting",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            },
            {
                "participantId": 11,
                "opinion": true
            }
        ]
    },
    {
        "id": 4232,
        "movieName": "Scream",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 115,
        "movieName": "The Big Lebowski",
        "movieYear": "1998",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 680,
        "movieName": "Pulp Fiction",
        "movieYear": "1987",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 15,
                "opinion": true
            }
        ]
    },
    {
        "id": 544,
        "movieName": "Den Där Mary",
        "movieYear": "1998",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 489,
        "movieName": "Good Will Hunting",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 197,
        "movieName": "Braveheart",
        "movieYear": "1995",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 114,
        "movieName": "Pretty Woman",
        "movieYear": "1990",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 18,
        "movieName": "Det Femte Elementet",
        "movieYear": "1997",
        "opinions": [
            {
                "participantId": 1,
                "opinion": false
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 105,
        "movieName": "Tillbaka Till Framtiden",
        "movieYear": "1985",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 268,
        "movieName": "Batman",
        "movieYear": "1989",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 2280,
        "movieName": "Big",
        "movieYear": "1988",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 860,
        "movieName": "Wargames",
        "movieYear": "1983",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 954,
        "movieName": "Mission Impossible",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 9603,
        "movieName": "Clueless",
        "movieYear": "1996",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 5503,
        "movieName": "Jagad",
        "movieYear": "1993",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 218,
        "movieName": "Terminator",
        "movieYear": "1984",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 280,
        "movieName": "Terminator 2: Judgement Day",
        "movieYear": "1991",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            },
            {
                "participantId": 16,
                "opinion": true
            }
        ]
    },
    {
        "id": 2062,
        "movieName": "Ratatouille",
        "movieYear": "2007",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 628,
        "movieName": "En vampyrs bekännelse",
        "movieYear": "1994",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": false
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 621,
        "movieName": "Grease",
        "movieYear": "1978",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 111,
        "movieName": "Scarface",
        "movieYear": "1983",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 613,
        "movieName": "Undergången",
        "movieYear": "2004",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 361743,
        "movieName": "Top Gun - Maverick",
        "movieYear": "2022",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 583,
        "movieName": "Life of Brian",
        "movieYear": "1979",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 12405,
        "movieName": "Slumdog Millionaire",
        "movieYear": "2008",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    },
    {
        "id": 694,
        "movieName": "The Shining",
        "movieYear": "1980",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": true
            }
        ]
    },
    {
        "id": 1597,
        "movieName": "Meet the Parents",
        "movieYear": "2000",
        "opinions": [
            {
                "participantId": 1,
                "opinion": true
            },
            {
                "participantId": 2,
                "opinion": false
            },
            {
                "participantId": 3,
                "opinion": true
            },
            {
                "participantId": 4,
                "opinion": false
            }
        ]
    }
]

export const Participants = [
    {
        "id": 1,
        "firstName": "Erik",
        "lastName": "Broström"
    },
    {
        "id": 2,
        "firstName": "Fanny",
        "lastName": "Agazzi"
    },
    {
        "id": 3,
        "firstName": "Martin",
        "lastName": "Soneby"
    },
    {
        "id": 4,
        "firstName": "Ola",
        "lastName": "Aurell"
    },
    {
        "id": 5,
        "firstName": "Jonas",
        "lastName": "Strandberg"
    },
    {
        "id": 6,
        "firstName": "Jonas",
        "lastName": "Pike"
    },
    {
        "id": 7,
        "firstName": "Kim W",
        "lastName": "Andersson"
    },
    {
        "id": 8,
        "firstName": "Ri",
        "lastName": "Versteeg"
    },
    {
        "id": 9,
        "firstName": "Sebastian",
        "lastName": "Järpehag"
    },
    {
        "id": 10,
        "firstName": "Adeel",
        "lastName": "Faqih"
    },
    {
        "id": 11,
        "firstName": "My",
        "lastName": "Gudmundsdotter"
    },
    {
        "id": 12,
        "firstName": "Tind",
        "lastName": "Soneby"
    },
    {
        "id": 13,
        "firstName": "Robin",
        "lastName": "Larsson Asp"
    },
    {
        "id": 14,
        "firstName": "Roland",
        "lastName": "Ulvselius"
    },
    {
        "id": 15,
        "firstName": "Julia",
        "lastName": "Finnsiö"
    },
    {
        "id": 16,
        "firstName": "Nils",
        "lastName": "Mattsson"
    }
]